
  @import url('https://fonts.googleapis.com/css2?family=Inter&family=Roboto:ital,wght@0,400;0,500;0,700;1,300;1,500&display=swap');

.app{
  font-family: 'Inter', sans-serif;
 
}

.pagination-container {
    margin-top: 30px;
    margin-bottom: 20px;
  text-align: center;
}
.pinnedSection{
  padding-left: 10px;
  display: flex;
  padding-top: 20px;
  padding-bottom: 50px;
  border-top: 2px solid #E0E0E0;
  gap: 10px;
  font-size: 10px;
  background-color: white;
}


.right-div{
  display: flex;
  flex-direction: column;
}

.titlesearch{
  display: flex;
  justify-content: space-between;
  gap:5px;
}

.pinnedSection{
  display: flex;
 
 
  overflow-y: hidden;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
 
}
#pinnedfrompage1{
  height: 100vh;
  margin-top: -2.5rem;
}
.pin-title-icon{
  display: flex;
  gap:5px;

}
.dummy-space{
  width: 2.28571429rem;
  height: 2.28571429rem;
}
.pin-popup{
  height:20px
}
.cardheader{
  display: flex;
  justify-content: space-between;
  margin-top: 0.3rem !important;
}


.pdfimagewrapper{
  display: flex;
  gap:0.5em;
}
.right-div{
  margin-top: 0.2em;
}
.right-div h4 {
  color: black;
  font-size: 10px;
  font-weight: 400;
}

.modal-upload-unpin{
}

.filetype{
  color: black;
  font-size: 11px;
  font-weight: bold ;
}
.titlesearch button{
  color: #1f97dc;
  font-size: 12px;
  font-weight: bold ;
}

.titleofcard{
  color: black;
  font-size: 12px;
  font-weight: bold ;
  
}
.dot-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.dot-loader span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 4px;
  opacity: 0.4;
  animation: loaderAnimation 0.7s infinite ease-in-out;
}

.dot-loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.dot-loader span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loaderAnimation {
  0% {
    transform: translateY(0);
    opacity: 0.4;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.4;
  }
}
.loader{
    display: flex;
    align-items: center;
    height: 100vh;
    margin-left: 50%;
}
.pdfimage{
  width: 39px;
  height: 120px;
  padding: 3px;
}

.linktoredirect{
  color:#1f97dc;
  font-size: 12px;
}
.navbar{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}
.popupPin{
 margin-left: 3.5rem;
 
  width: 90rem;
}
/* .xauth-loader-pin{
  margin-top: -116%;
  height: 26px;
  margin-left: -7%;
} */
.header-loader-pin {
  display: none;
  padding-left: 62.45rem;
  margin-top: -15.2px;
}
.ing-loader-pin , .xauth-loader-pin{
margin-top: -117%;
  height: 26px;
  margin-left: -4%;

  margin-bottom: 35%;
}
.unpinpopup{
  display: flex;
}

.unpinpopup .imageforunpin{
  padding-right: 10px;
}


.pin-header{
  display: flex;
  
 
}


.series-wrapper{
  padding-top: 15px;
}
#filterButton{
 
  border-color: #0971CE;
}
.spinner-pdf{

    display: flex;
    align-items: center;
    height: 100vh;
    width: 150vh;
 
}
.pdfViewer{
  margin-right: 20% !important;
 
}

.proced-desc{
 margin-left: 35px;
  padding-right: 15px;
}
.proced-desc ul {
  list-style-type:disc;
  padding-left: 20px;
}

.proced-desc ol {
  list-style-type: decimal;
  padding-left: 20px;
}





#proced{
  margin-left: 16px;
}
.tableOfContentsauth{
  cursor: pointer;
  padding-bottom: 2px;
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
  padding-bottom: -3px;
  margin-left: 10px;
  padding-left: 10px;
  /* padding-top: 5px; */
  cursor: pointer;
  border-radius: 5px;
 
}
/* .tableContentsStyleHighlight{
  
}
.tableContentsStyle{
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "5px",
  marginLeft: "10px",
  paddingLeft: "10px",
  paddingTop: "10px",
  cursor: "pointer",

  borderRadius: "5px",
} */
.sidebar__highlightauth{
  padding-top: 8px !important;
  margin-right: 5px;
  cursor: pointer;
}
.sidebar__highlight{
  padding-top: 8px !important;
  margin-right: 5px;
  cursor: pointer;
}
.sidebarContent{
  margin-top: 2px;
  margin-right:5px;
}
.sidebar-ul{
  padding-right: 6px  !important;
}
.highlighted {
  background-color:rgba(255, 226, 143, 1);
 }
 .proced-desc table, th, td{
  border: 1px solid black;
border-collapse: collapse;
}
.authContent{

  width: 76vw;
  position: relative;
height: 100vh;
  overflow: auto;
  padding-top: 10px;
}
.fr-emoticon{
  background-repeat: no-repeat !important;
    font-size: inherit !important;
    height: 1em !important;
    width: 1em !important;
    min-height: 20px !important;
    min-width: 20px !important;
    display: inline-block !important;
    margin: -.1em .1em .1em !important;
    line-height: 1 !important;
    vertical-align: middle !important;
}
.procedTitle{
  font-weight: "bold";
  font-size: "16px";
  font-family: "Inter";


}
.proced-desc{
  overflow-y: auto;
  padding-top: 20px;
  

}
.video{
padding-top: 10px;
width: 400px;
padding-bottom: 10px;

}
.procedsection{
  padding-bottom: 20px;
  margin-left: 20px;
}
.step-title{
  display: "flex";
  justify-content: "space-between";
  padding-top: "20px"
}
.tableOfContents{
display: flex;
justify-content: space-between;
padding-bottom: 15px;
margin-left : 10px;
padding-left: 10px;
padding-top: 5px;
cursor: pointer;
margin-right: 10px;
border-radius: 5px;
               
}
.sidebar{
  padding-top: 20px;
  width: 20vw;
  overflow: auto;
  border-right: 1px solid #E0E0E0;
}
.sidebar-text{
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Inter',
}
.arrow-icon{
  height: 20px!important;
  margin-right: 15px!important;
  margin-top: 15px!important;
  cursor: pointer!important;
  font-weight: light!important;
  font-size: 10px!important;
}
.arrow-icon-auth{
  height: 20px!important;
  margin-right: 15px!important;
  margin-top: 14px!important;
  cursor: pointer!important;
  font-weight: light!important;
  font-size: 10px !important;
}
.modal-upload-delete {
  position: relative !important;
  width: 730px !important;
  height: auto !important;
  min-height: 550px !important;
  left: 0px;
  top: 0px;
  background: #ffffff !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px !important;
}

.feedback-description {
  width: 100%;
  height: 15rem;
  border: 1px solid black;
}
.category-dropdown {
  width: 100%;
  height: 4rem;
  border: 1px solid black;
 
}
.secondaryButton {
  box-sizing: border-box;
  width: 125px;
  height: 40px;
  left: 695px;
  top: 187px;
  opacity: 1;
  background: #ffffff !important;
  border: 1px solid #0971ce !important;
  border-radius: 4px;
}
.stepTitle{
  font-weight: bold;
  font-size: 16px;
  margin-left: 20px;

}
.step-content{
  margin-left: 20px;
}
.threedots-feedback{
    height: 20px;
    cursor: pointer;
    padding-top: 5px;
}
.loader-feedback{
    margin-top: -2.5rem;
    margin-left: 6rem;
}
.primaryButton {
  box-sizing: border-box;
  width: 125px;
  height: 40px;
  left: 942px;
  top: 187px;
  color: white !important;
  background: #034e91 !important;
  border: 1px solid #0971ce !important;
  border-radius: 4px;
  opacity: 1;
}
.feedback-btn-wrap {
  display: flex;
  justify-content: flex-end;
  padding: 0.7rem;
}
.feedback-btn-wrap-list {
  display: flex;
  justify-content: flex-end;
  padding: 1.7rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

.feedback-content-wrapup{
  min-height: fit-content;
}
.takefile {
  width: 56px;
  height: 56px;
  border-radius: 4px;
  margin-right: 8px;
  opacity: 0;
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  padding: 0.3em 1em;
  display: none;
}


.video-display {
  height: 20%;
  width: 20%;
  padding: 5px;
  cursor: pointer;
}

.image-display {
  height: 40%;
  width: 40%;
  padding-right: 2rem;
  cursor: pointer;
}
.image-display-feedback {
  height: 20%;
  width: 20%;
  padding-right: 2rem;
  cursor: pointer;
}
.parentForm,
.upload-file {
  padding: 55px 0px 50px 0px;
  margin: 20px 0px;
}
.upload-file {
  padding-left: 20px;
  width: 50%;
  float: left;
}  


.modal-upload-delete {
  position: relative !important;
  width: 730px !important;
  height: auto !important;
  min-height: 550px !important;
  left: 0px;
  top: 0px;
  background: #ffffff !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px !important;
}
.feedback-img {
  width: 25%;
  height: 25%;
  padding: 0.5rem;
  cursor: pointer;
}
.close-button-position {
  position: absolute;
    left: 130px;
}
.video-close-button-position
{
  position: absolute;
 
  left: 155px;
}
.modal-upload-image{
  text-align: center !important;
  height: fit-content !important;
  position: absolute !important;

}
.upload-media-popup{
   font-size: 10px !important;
   background: rgb(242 247 251) !important;
}
.render-uploadmedia-container{
 display: flex;
  padding-bottom: 5px 
}
.feedbackform-container{
 padding: .5rem; 

}
.instruction-upload-media{
   font-style: italic; 
   color: #034E91;
}
.image-fullscreen{
  width: 80%;
  height: 80%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 6rem;
}
.image-close{
  position:absolute;
  top:0;
  right:0;
  font-weight: bolder;
  margin: 6px;
}

.cardtot{
  cursor: default !important;
  height: 15em !important;
}

.titleofcard{
  cursor: pointer !important;
}

.defaultbutton{
  cursor: default !important;
}

.centered-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content {
  max-width: 400px;
  padding: 20px;
  text-align: center;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-content h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

.card-content a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}
.pagination-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}
.stepHeader{
  display: flex;
justify-content: space-between;
  padding-top: 20px;

  background-color: #FFF6DB;
}
.print-file{
    display: none;
  }
  .xauth{
    background-color: #2185d0;
  }
  .ing{ 
    background-color: #f26202;
  }
  .tag-icon{
    width: 30px;
    height: 33px;
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .xauth-icon {
    color: white !important;
    font-family: inherit;
    text-align: center !important;
  }
  #unpin-popup{
    font-size: 10px;
  }
  .PdfHighlighter {
    position: absolute !important;
    overflow: auto!important;
    width: 77%!important;
    height: 100%!important;
   
}
.raiseAFeedback{
margin-top: -5px !important;
 
}
@media only screen and (max-width: 767px) {
  .qaenginebox{
    margin-left: 12%;
    margin-bottom: 5%;
    margin-top: -15%;
  }
  .resultbox{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #backgroundColorPage1{
    overflow-y: scroll;
  }
  /* Webkit (Safari, Chrome) */
  #backgroundColorPage1::-webkit-scrollbar {
    display: none;
  }

  .cardtot{
    cursor: default !important;
    height: 15em !important;
    width: 90% !important;
  }
  .cardgroup{
    justify-content: center;
  }
  .popupPin{
    width: 20rem;
    margin-left: 9%;
  }
  .pin-title-icon {
    display: flex;
    gap: 5px;
    padding-left: 9px;
}
.titlesearch button {
  color: #1f97dc;
  font-size: 12px;
  font-weight: bold;
  padding-right: 13px;
}
.titlesearch-nodoc{
  display: flex;
 justify-content: space-between;
}
.pagination-container{
  display: flex;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  width: 100%;

}
.pagination-container::-webkit-scrollbar {
  display: none;
}
.stepHeader{
  display: flex;
justify-content: space-between;
  padding-top: 20px;
  background-color: #FFF6DB;
}
.modal-upload-delete {
  position: relative !important;
  width: 330px !important;
  height: auto !important;
  min-height: 550px !important;
  left: 0px;
  top: 0px;
  background: #ffffff !important;
  box-shadow: 2px 9px 8px rgba(0, 0, 0, 0.18);
  border-radius: 4px !important;
}
.tableOfContents{
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-left : 4px;
  padding-left: 10px;
 
  padding-top: 10px;
  cursor: pointer;
  border-radius: 5px;
  width: 90%;
  }
  .tableOfContentsauth{
    cursor: pointer;
    padding-bottom: 2px;
    padding-top: 2px;
    display: flex;
    
    justify-content: space-between;
    padding-bottom: 5px;
    margin-left: 5px;
    padding-left: 10px;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 5px;
   
  }
  .sidebar-text{
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 7px;
    line-height: 7px;
    font-family: 'Inter',
  }
 
  .arrow-icon{
    height: 20px!important;
    margin-right: 15px!important;
    margin-top: 15px!important;
    cursor: pointer!important;
    font-weight: light!important;
    font-size: 5px!important;
  }
  .arrow-icon-auth{
    height: 20px!important;
    margin-right: 15px!important;
    margin-top: 9px!important;
    cursor: pointer!important;
    font-weight: light!important;
    font-size: 5px!important;
  }
  .sidebarContent{
    margin-top: 2px;
    margin-right:5px;
    font-size: 7px;
  }
  .sidebar-ul{
    padding-right: 3px !important;
  }

  /* .xauth-loader-pin, .ing-loader-pin{
    margin-top: -55%;
    height: 10px;
    margin-left: 2px;
    margin-right: -39px;
  } */
  /* .ing-loader-pin{
    margin-top: -122%;
    height: 10px;
    margin-left: 2px;
    margin-right: -39px;
  } */

}
@media print {
  /* Hide print button */
  .no-print {
    display: none;
  }
  .print-file{
    display: block;
    margin-left: 50%;
  }
}