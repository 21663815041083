@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .userOptionsBtns {
        @apply align-middle text-lg m-1;
    }

    .userOptionsBtnsClicked {
        @apply bg-blue-200 bg-clip-padding;
    }
}
/* home page background color class */
#backgroundColorPage1 {
    overflow-y: hidden;
    background: rgb(255, 251, 241);
    background: -moz-linear-gradient(0deg, rgba(255, 251, 241, 0.9624182436646533) 0%, rgba(255, 255, 255, 1) 48%);
    background: -webkit-linear-gradient(0deg, rgba(255, 251, 241, 0.9624182436646533) 0%, rgba(255, 255, 255, 1) 48%);
    background: linear-gradient(5deg,rgb(255 244 216 / 61%),#eeffff87 78%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffbf1", endColorstr="#ffffff", GradientType=1);
}

/* uparrow and uparrowfilter both used in searchresultoption component in "search result" folder */
.uparrow{
    border: 1px solid rgb(220, 220, 220);
}
.uparrow::after,.uparrow::before{
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}

.uparrow:after {
    bottom: -2px;
    left: 100px;
    border-color: transparent transparent white transparent ;
    border-width: 15px;
}

.uparrow:before {
    bottom: -1px;
    left: 100px;
    border-color: transparent transparent rgb(220, 220, 220) transparent ;
    border-width: 15px;
}

.uparrowfilter {
    border: 1px solid rgb(220, 220, 220);
}

.uparrowfilter:after,
.uparrowfilter:before {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}

.uparrowfilter:after {
    bottom: -2px;
    left: 260px;
    border-color: transparent transparent white transparent ;
    border-width: 15px;
}

.uparrowfilter:before {
    bottom: -1px;
    left: 260px;
    border-color: transparent transparent rgb(220, 220, 220) transparent ;
    border-width: 15px;
}


.hover_to_show_how_many_items_can_be_pinned:hover ~ .hovered_show_value{
    @apply block;
}

.searchbutton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.searchbuttononresultsearchbox{
    padding-left: 10px;
}

.logout_button{
    padding-right: 30px;
    padding-top: 30px;
}

.beta-tag {
    margin-left: 13px;
    margin-top: 4px;
}

.pinImagenonauth{
    align-self: flex-end;
}

.titleandpin{
    display: flex;
    justify-content: space-between;
}
.icon-feedback{
    border-radius: 50%;
    height:25px;
    width: 25px;
    background-color: rgb(212, 210, 210);
    display: inline-block;
    text-align: center;
    margin-right: 5%;
  }
  
  .pointer-icon{
      display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon-feedback:hover{
      background-color: rgb(183, 210, 245);
  }

  .textLayer ::selection{
    color: transparent;
  } 
